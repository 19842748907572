import React, { PureComponent } from 'react'
import { helpers } from '@common'
import styles from './style.m.scss'
import Icon from '../Icon'
import BizTracker from '~/common/tracker/bizTracker'

import icon_1 from '~/assets/img/1@3x.png'
import icon_2 from '~/assets/img/2@3x.png'
import icon_3 from '~/assets/img/3@3x.png'
import icon_tips from '~/assets/img/tips@3x.png'

const className = helpers.classNames.react(styles)

export class ForgetPassword extends PureComponent {
  componentWillUnmount() {
    BizTracker.click({ cn: 2, screenNum: 390002 })
  }

  render() {
    const { tabList, onChoose } = this.props
    const { translate } = this.props

    return (
      <div {...className('forget-password-container')}>
        <div {...className('yellow-tips')}>
          <Icon icontype="image" src={icon_tips} {...className('tips-icon')} />
          <p>{translate('为了您的账号安全，请您到AKULAKU上找回您的密码')}</p>
        </div>
        <div {...className('forget-password-item')}>
          <Icon
            icontype="image"
            src={icon_1}
            {...className('item-icon shadow-icon')}
          />
          <div>
            <p>{translate('第一步')}</p>
            <p>{translate('下载并打开Akulaku APP')}</p>
          </div>
        </div>
        <div {...className('vertical-dash')} />
        <div {...className('forget-password-item')}>
          <Icon icontype="image" src={icon_2} {...className('item-icon')} />
          <div>
            <p>{translate('第二步')}</p>
            <p>{translate('点击登录')}</p>
          </div>
        </div>
        <div {...className('vertical-dash')} />
        <div {...className('forget-password-item')}>
          <Icon icontype="image" src={icon_3} {...className('item-icon')} />
          <div>
            <p>{translate('第三步')}</p>
            <p>{translate('点击‘忘记密码’进行找回')}</p>
          </div>
        </div>
      </div>
    )
  }
}
