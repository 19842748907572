import { classNames } from '@common/helpers'
import React, { FC } from 'react'
import styles from './index.scss'

interface Props {
  /**
   * 默认为公告的样式
   * @default notice
   */
  type?: 'notice' | 'warning'
}

export const NoticeCard: FC<Props> = (props) => {
  let { children } = props
  const type = props.type ?? 'notice'

  if (process.env.NODE_ENV === 'development') {
    children ||= '只在开发环境调试用的notice'
  }

  if (!children) {
    return null
  }

  return (
    <div
      className={classNames(
        styles.notice,
        type === 'warning' && styles.warning,
      )}
    >
      {children}
    </div>
  )
}
