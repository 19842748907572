import { useBasicContext } from '@common/Basic'
import { classNames } from '@common/helpers'
import { Accordion } from 'antd-mobile'
import _ from 'lodash'
import { useState, ReactNode, FC } from 'react'
import { Translate } from '~/config/translate'
import styles from './index.scss'

interface ListItem {
  periods: number
  monthlyPay?: number

  //region 下单页获取到的数据格式
  downPayment?: number
  showInterestFreeLabel?: boolean
  lastMonthlyPay?: number

  /**
   * 分期总优惠金额
   * 只会在使用利息券的时候返回
   */
  periodsTotalDiscount?: number

  interestDiscountLabelContent?: string
  //endregion
}

interface InstallmentPlanProps {
  /**
   * 可用分期列表
   */
  list: ListItem[]
  /**
   * 渲染在分期计划选择box 后一个元素
   */
  renderAfterList?: () => ReactNode
  /**
   * 当前所选分期
   */
  curPeriod: string
  onCurPeriodChange: (period: string, item: ListItem) => void
  /**
   * 订单金额
   */
  payAmount: number
  /**
   * 商户名称
   */
  merchantName?: string
  /**
   * 汇率信息
   */
  exchangeRateVO?: any
  /**
   * 商户优惠券
   */
  merchantCouponVOS?: any
  /**
   * 商户订单原始金额
   */
  merchantOriginPrice: any
  /**
   * 折扣金额
   */
  discountAmount?: number
  /**
   * 首付
   */
  downPayment?: number
  className?: string

  periodOfInterestFree?: string | number

  tips?: ReactNode
  /**
   * 渲染 tips 下一个元素
   */
  renderAfterTips?: () => ReactNode

  /**
   * 是否显示首付模块
   * 在 下单页 总是显示首付模块，包括0首付的单
   */
  showDownPayment?: boolean

  /**
   * 主要给埋点用，展开关闭的时候需要埋点
   */
  onExpandChange?: (expand: boolean) => void
  /** 活动信息 */
  activityInfo?: any
  /** 是否展示活动最大优惠金额 */
  showPromotionAmount: boolean
}

export function InstallmentPlan(props: InstallmentPlanProps) {
  const { translate } = Translate.getInstance()
  const {
    tips,
    renderAfterList,
    renderAfterTips,
    // downPayment,
    discountAmount,
    periodOfInterestFree,
    // showDownPayment,
    merchantName,
    exchangeRateVO,
    merchantCouponVOS,
    payAmount,
    merchantOriginPrice,
    activityInfo,
    showPromotionAmount,
  } = props

  //region 展开收起控制
  const [open, setOpen] = useState('')

  function handleOpenChange(name: string) {
    setOpen(name)
    props.onExpandChange?.(name === 'panel')
  }
  //endregion

  const { currencyCode, formatPrice } = useBasicContext()

  function toDisplayPrice(price?: number): string {
    return `${currencyCode}${formatPrice(price ?? '')}`
  }

  function toDisplayText(curPeriod?: StringOrNumber, price?: number): string {
    const priceText = `${currencyCode}${formatPrice(price ?? '')}`
    return `${priceText} x ${curPeriod}${translate('月')}`
  }

  const curSelected = _.find(props.list, { periods: +props.curPeriod })
  // 支付货币
  const payCurrency = exchangeRateVO ? exchangeRateVO.get('payCurrency') : ''
  // 兑换货币
  const payToCurrency = exchangeRateVO
    ? exchangeRateVO.get('payToCurrency')
    : ''

  // 汇率
  const rate = exchangeRateVO ? exchangeRateVO.get('rate') : ''
  const orginPrice = payAmount + (discountAmount || 0)

  // 原始金额
  const merchantOriginPriceValue = merchantOriginPrice
    ? merchantOriginPrice.get('value')
    : ''
  // 原始金额货币
  const merchantOriginPriceCurrency = merchantOriginPrice
    ? merchantOriginPrice.get('currency')
    : ''

  // 商户优惠
  const merchantCouponVOSValue = _.reduce(
    _.result(merchantCouponVOS, 'toJS') || [],
    (pre, cur) => pre + Number(_.get(cur, 'amount.value', 0)),
    0,
  )

  const discountTotalAmount =
    Number(discountAmount || 0) +
    Number(merchantCouponVOSValue || 0) +
    Number(_.get(activityInfo, 'actualDiscountAmount', 0))

  // 是否是随机立减
  const isRandomDiscount = activityInfo?.promotionPriceType === 0

  return (
    <div className={classNames(styles.installment, props.className)}>
      {!!merchantName && (
        <div className={styles.row}>
          <span className={styles.label}>{translate('付给')}</span>
          <span
            className={classNames(
              styles.value,
              styles.payAmount,
              'font-roboto-bold',
            )}
          >
            {merchantName}
          </span>
        </div>
      )}

      {rate || merchantOriginPriceValue ? (
        <Accordion
          className={styles.row}
          activeKey={open}
          onChange={handleOpenChange}
          accordion
        >
          <Accordion.Panel
            key={'panel'}
            header={
              <div className={styles.row}>
                <span className={styles.label}>{translate('订单金额')}</span>
                <span
                  className={classNames(
                    styles.value,
                    styles.payAmount,
                    'font-roboto-bold',
                  )}
                >
                  {!!discountTotalAmount && (
                    <span className={`font-regular ${styles.discount}`}>
                      {translate('优惠', {
                        str: toDisplayPrice(discountTotalAmount),
                      })}
                    </span>
                  )}
                  {toDisplayPrice(payAmount)}
                </span>
              </div>
            }
          >
            {rate ? (
              <div className={styles.row}>
                <span className={styles.label}>{translate('汇率')}</span>
                <span
                  className={classNames(
                    styles.value,
                    styles.payAmount,
                    'font-roboto-bold',
                  )}
                >
                  {`${payCurrency}1=${payToCurrency}${rate}`}
                </span>
              </div>
            ) : null}
            {merchantOriginPriceValue ? (
              <div className={styles.row}>
                <span className={styles.label}>{translate('原始金额')}</span>
                <span
                  className={classNames(
                    styles.value,
                    styles.payAmount,
                    'font-roboto-bold',
                  )}
                >
                  {merchantOriginPriceCurrency + merchantOriginPriceValue}
                </span>
              </div>
            ) : null}
          </Accordion.Panel>
        </Accordion>
      ) : (
        <div className={styles.row}>
          <span className={styles.label}>{translate('订单金额')}</span>
          <span
            className={classNames(
              styles.value,
              styles.payAmount,
              'font-roboto-bold',
            )}
          >
            {!!discountTotalAmount && (
              <span className={`font-regular ${styles.discount}`}>
                {translate('优惠', {
                  str: toDisplayPrice(discountTotalAmount),
                })}
              </span>
            )}
            {toDisplayPrice(payAmount)}
          </span>
        </div>
      )}

      {activityInfo ? (
        <div className={classNames(styles.row, styles.promotion)}>
          <img className={styles.icon} src={require('./images/frame.png')} />
          <div className={styles.text}>
            {showPromotionAmount ? null : activityInfo?.activityTitle}
            {showPromotionAmount ? (
              <span>
                {isRandomDiscount
                  ? `s.d ${toDisplayPrice(activityInfo?.maxDiscountAmount)}`
                  : toDisplayPrice(activityInfo?.actualDiscountAmount)}
              </span>
            ) : null}
          </div>
        </div>
      ) : null}

      {/* 分期计划 */}
      <div className={styles.plan_container}>
        <div className={classNames(styles.title, 'font-roboto-bold')}>
          {translate('分期计划')}
        </div>

        <div className={'flex flex-wrap'}>
          {_.map(props.list, (item) => {
            const itemPeriod = String(item.periods)
            const active = itemPeriod === props.curPeriod

            const showDiscountBadge =
              String(periodOfInterestFree) === itemPeriod ||
              item.showInterestFreeLabel
            return (
              <div className={styles.planItemContainer} key={itemPeriod}>
                <div
                  className={classNames(
                    `font-roboto-medium`,
                    styles.planItem,
                    active && styles.active,
                  )}
                  onClick={() => props.onCurPeriodChange(itemPeriod, item)}
                >
                  <span>{toDisplayText(itemPeriod, item?.monthlyPay)}</span>
                  {!!item.periodsTotalDiscount && (
                    <span className={styles.planItemDiscount}>
                      {translate('优惠', {
                        str: toDisplayPrice(item.periodsTotalDiscount),
                      })}
                    </span>
                  )}

                  {showDiscountBadge && (
                    <DiscountBadge>
                      {item.interestDiscountLabelContent || translate('免息')}
                    </DiscountBadge>
                  )}
                </div>
              </div>
            )
          })}
        </div>

        {renderAfterList?.()}

        {tips && (
          <div className={styles.tipsRow}>
            <img src={require('./images/info.png')} />
            <span>{tips}</span>
          </div>
        )}
        {renderAfterTips?.()}

        {/* {showDownPayment && (
          <div className={classNames(styles.row, styles.downPayment)}>
            <span className={styles.label}>{translate('首付')}</span>
            <span className={`font-roboto-bold ${styles.value}`}>
              {toDisplayPrice(downPayment)}
            </span>
          </div>
        )} */}
      </div>
    </div>
  )
}

const DiscountBadge: FC = (props) => {
  return (
    <div className={styles.badgeContainer}>
      <div className={`font-roboto-medium ${styles.badge}`}>
        <span>{props.children}</span>
      </div>
      <div className={styles.triangle}></div>
    </div>
  )
}
