import { useDispatch } from 'react-redux'
import { globalActionCreator } from '~/common/globalActions'
import { Translate } from '~/config/translate'

interface Options {
  // 打开时的回调
  onOpen?: AnyFunction
  // 点击弹框取消回调
  onCancel?: AnyFunction
  // 点击弹框确定回调
  onConfirm?: AnyFunction
}

export function useOpenLogoutDialog(options: Options = {}) {
  const translate = Translate.getInstance().translate
  const dispatch = useDispatch()

  return function () {
    options.onOpen?.()
    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        closable: true,
        title: translate('登出'),
        content: translate('确认退出登录？'),
        footer: [
          {
            text: translate('退出登录_dialog_确定按钮'),
            onPress: () => {
              options.onConfirm?.()
              dispatch(globalActionCreator.globalLogOut())
            },
          },
          {
            text: translate('退出登录_dialog_取消按钮'),
            onPress: () => {
              options.onCancel?.()
            },
          },
        ],
      }),
    )
  }
}
