import { classNames } from '@common/helpers'
import _ from 'lodash'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useOpenLogoutDialog } from '~/hooks/useOpenLogoutDialog'
import styles from './index.scss'

interface Props {
  className?: string
  /**
   * 退出弹框点击确定
   */
  onLogoutConfirm?: AnyFunction
  /**
   * 退出弹框点击取消
   */
  onLogoutCancel?: AnyFunction
  /**
   * 点击退出按钮
   */
  onLogoutBtnClick?: AnyFunction

  /**
   * 目前只会在login界面传递，使用接口返回的数据
   */
  rightText?: string
}

export function Header(props: Props) {
  const openDialog = useOpenLogoutDialog({
    onOpen: props.onLogoutBtnClick,
    onCancel: props.onLogoutCancel,
    onConfirm: props.onLogoutConfirm,
  })

  /**
   * 在orderDetail界面使用的时候会获取到脱敏的手机号
   */
  const phoneNumber = useSelector((state) => {
    return state.getIn(['orderDetail', 'commonInfo', 'phoneNumber'])
  })
  // 能获取到脱敏的手机号即视为已登录
  const isLogined = !!phoneNumber

  return (
    <div
      className={classNames(
        styles.header,
        isLogined && styles.logined,
        props.className,
      )}
    >
      <img
        src={
          isLogined
            ? require('./images/akulaku_logo_nav.png')
            : require('./images/notLoginedLogo.png')
        }
        alt={'logo'}
        className={styles.logo}
      />
      {isLogined ? (
        <>
          <h2 className={'font-roboto-medium'}>{phoneNumber}</h2>
          <img
            src={require('./images/logout.png')}
            alt=""
            className={styles.logout}
            onClick={openDialog}
          />
        </>
      ) : (
        <span className={styles.text}>
          {/* 设计反馈更改：*/}
          {/* 这个设置个最长宽度,限制下字符数最长32个,超出的显示... */}
          {_.truncate(props.rightText, { length: 32 + 3 })}
        </span>
      )}
    </div>
  )
}

/**
 * 背景色是透明的header
 * 所用的图片大小也不一致
 * 这里也没有登录态的判断，基本上是用在已登录场景
 */
export const HeaderTransparent: FC<
  Props & { className?: string; phoneNumber?: string; theme?: 'light' | 'dark' }
> = (props) => {
  // 主要是 文字颜色 和 按钮颜色
  // 如果是dark主题，则使白色的文字和按钮颜色
  // 如果是light主题，则使黑色的文字和按钮颜色
  const isLightTheme = props.theme === 'light'

  const openDialog = useOpenLogoutDialog({
    onOpen: props.onLogoutBtnClick,
    onCancel: props.onLogoutCancel,
    onConfirm: props.onLogoutConfirm,
  })
  return (
    <div
      className={classNames(
        props.className,
        'flex items-center px-16pt',
        'box-content',
        isLightTheme ? 'pt-18pt pb-10pt' : 'pt-17pt pb-15pt',
        isLightTheme ? 'h-24pt' : 'h-36pt',
      )}
    >
      <img
        src={require('./images/logo.transparent.png')}
        className={'w-26pt h-26pt mr-7pt'}
      />

      <span
        className={classNames(
          'text-15pt leading-none font-roboto-medium flex-1',
          isLightTheme ? 'text-#282B2E' : 'text-white',
        )}
      >
        {props.phoneNumber}
      </span>

      <img
        onClick={openDialog}
        className={isLightTheme ? 'h-20pt h-20pt' : 'h-36pt w-36pt'}
        src={
          isLightTheme
            ? require('./images/logout.png')
            : require('./images/logout.transparent.png')
        }
      />
    </div>
  )
}
